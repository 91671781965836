<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CCNL_TYPES } from './store'
import { ROUTES as CCNL_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import CcnlContractTable from './CcnlContractTable'
import CcnlContractCommonFilters from './CcnlContractCommonFilters'
import CcnlContractEdit from './CcnlContractEdit.vue'

export default {
  name: 'CcnlContractList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': CcnlContractTable,
    'common-filters': CcnlContractCommonFilters,
    'component-detail': CcnlContractEdit,
    'component-edit': CcnlContractEdit
  },
  data () {
    return {
      CCNL_ROUTES,
      title: this.$t('CCNL contracts'),
      rolePerm: ['miscellaneous_ccnlcontract_list'],
      actionEnablePermission: ['miscellaneous_ccnlcontract_enable'],
      actionDisablePermission: ['miscellaneous_ccnlcontract_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CCNL_TYPES.GENERIC.miscellaneous.ccnlcontract.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.CCNL_ROUTES.CCNL_CONTRACT_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_ccnlcontract_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CCNL_TYPES.GENERIC.miscellaneous.ccnlcontract.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CCNL_TYPES.GENERIC.miscellaneous.ccnlcontract.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CCNL_TYPES.GENERIC.miscellaneous.ccnlcontract.LIST.ACTIONS
    })
  }
}
</script>
